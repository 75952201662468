.cookieBanner_container {
  background: $colorMain;
  position: sticky;
  width: 100%;
  z-index: 11111;
  padding: 15px;
  padding-bottom: 50px;
  bottom: 0;

  h4, p, i {
    color: white;
  }

  h4 {
    font-family: $fontTitle;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  p {
    font-family: $fontP;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15em;
  }

  .cookie {
    font-size: 72px;
    margin: 32px 0;
  }

  .cookieBanner_header {
    text-align: right;

    i {
      cursor: pointer;
    }
  }

  button {
    color: $colorMain;
    background: white;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
  }
}