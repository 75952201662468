.overlay_container {
  background: #000000ba;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 111;
}

.shoebox_container {
  background: white;
  position: fixed;
  text-align: left;
  min-width: 400px;
  width: 70vw;
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  z-index: 1111;

  .shoebox_header {
    text-align: right;

    i {
      cursor: pointer;
      padding: 15px;
      &:hover {
        color: grey;
      }
    }
  }

  h4 {
    font-family: $fontTitle;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    line-height: normal;
    text-align: left;
    letter-spacing:0.15em;
  }
}

.shoebox_pointer {
  background: $colorAccent;
  background: white;
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 111;
  transition: transform 0.42s ease-in-out;

  .shoebox_tag {
    background: rgba(255,255,255,0.8);
    position: relative;
    top: -50%;
    left: -50%;
    width: 100px;
    padding: 5px;
    border-radius: 3px;
  }

  &::after {
    background: rgba(255, 255, 255, 0.2);
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    top: 0;
    left: 0;
    animation: ping 3s ease-in-out infinite;
  }

  &:hover {
    transform: scale(0.87);
  }
}
.shoebox_title {
  margin-left: 15px;
  position: absolute;
}


@media screen and (max-width: 460px) {
  .shoebox_title {
    position: relative;
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15% {
    opacity: 1;
  }
  30% {
    transform: scale(2.6);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}