.page-login {
  background: darkslategray;
  width: 100%;
  height: 100%;
}

.form_container-login {
  background: #F2F2F2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  border-radius: 5px;
  border: 1px solid #F2F2F2;
  padding: 15px;

  .form_container-header {
    padding: 30px 0;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }

  .form_container-input {
    position: relative;
    display: flex;
    align-items: baseline;

    i {
      margin-right: 15px;
    }
    input {
      width: 100%;
    }

    .form_input-visible {
      position: absolute;
      bottom: 0;
      right: 0;
      height: calc(100% - 15px);
      i {
        vertical-align: sub;
      }
    }
  }

  label {
    text-align: left;
    display: block;
    padding: 15px;

    input {
      padding: 10px;
      margin-top: 10px;
      border-radius: 3px;
      border: none;
    }
  }
}

// tabs
.tab_admin {
  background: none;
  border: none;
  margin: 0 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: $colorText;
  border-bottom: 1px solid transparent;
  &:hover, &.activeadmin {
    color: $colorAccent;
    border-bottom: 1px solid $colorAccent;
  }
}

// btn admin
.btn_admin {
  background: $colorAccent;
  color: white;
  position: relative;
  border-radius: 5px;
  min-width: 200px;
  margin: 15px;
  border: none;
  cursor: pointer;
  padding: 15px;
  transition: background-color .3s;
  &.btn_admin-border {
    border: 1px solid $colorAccent;
    color: $colorAccent;
    background: white;
  }
  &:disabled {
    background-color: transparentize($colorAccent, .4);
  }
}

.btn_admin-saving {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  animation: saving-animation 4s linear infinite;
}


.btn_valid {
  position: absolute;
  top: 0;
  right: 0;
  background: $colorAccent;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.price_currency {
  position: absolute;
  top: 0;
  right: 0;
}

@keyframes saving-animation {
  from { transform: translateY(-50%) rotate(0deg); }
  to { transform: translateY(-50%) rotate(360deg); }
}
