// Image
.image_container {
  position: relative;
  overflow: hidden;

  .image_source {
    width: 100%;
    height: 100%;
  }
  .image_source.image_shoebox {
    background-size: cover;
    background-position: center;
    /* min-height: 90vh; */
    height:500px;
  }
}


// Image placeholder
.image_placeholder {
  background: #C4C4C4;
  position: relative;
  display: block;
  height: 100%;

  i {
    position: absolute;
    font-size: 64px;
    color: $colorText;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


@media screen and (max-width: 960px) {

  .image_container {
    width: 100%!important;
    min-height: 300px;
    order: 1;
  }

}


@media screen and (max-width: 640px) {

  .image_container, .content_container {
    min-width: 100%!important;
  }
}
