.full_container {
  padding: 100px 0 200px;
  display: block;
  background: black;
  color: white;

  &.white {
    background: white;
    color: black;
  }
}

@media screen and (max-width: 960px) {
  .full_container {
    span {
      max-width: 100%!important;
    }
  }
}