.cows {
  position: absolute;
  bottom:0;
  left: 0;

  .cow {
    transform: scaleX(-1);
    width: 100px;
    height: 100px;
  }
}