// Header
.navbar_container {
  display: flex;
  background: white;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.btn_burger {
  border: none;
  position: absolute;
  right:0;
  background: none;
  padding: 5px 10px;
  font-size: 28px;
}

@media screen and (min-width: 960px) {
  .btn_burger {
    display:none;
  }
}

.menu_container {
  width: 100%;
}

.desktop_container {
  color: black;
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 45px;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.15em;

  .menu_item {
    padding:0 15px;
    text-decoration: none;
    position: relative;
    color: #C4C4C4;
    overflow: hidden;

    &[aria-current="page"] {
      color: black;
      &::after {
        left: 0;
      }
    }

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 5px;
      background: $colorAccent;
      content: "";
      transition: left 0.31s ease-out;
    }

    &:hover {
      &::after {
        left: 0;
      }
    }

    &:hover, &:active, &:focus, &:focus-within {
      color: black;
    }
  }
}

@media screen and (max-width: 960px) {
  .desktop_container {
    display:none;
  }
}

.burger_container {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  width: 90vw;
  height: 100vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  animation: appear 0.5s ease;

  .menu_item {
    color: inherit;
    text-decoration: inherit;
  }

  ul {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      color: black;
      text-align: left;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      width: 100%;
      list-style-type: none;
      margin-left: 15px;
      border-bottom: 1px solid #F2F2F2;

      i {
        margin-right: 15px;
      }

      a {
        display: block;
        padding: 25px 10px;
      }
    }
  }
}

.logo_container {
  img {
    width: 150px;
    padding: 12px;
  }
}

@keyframes appear {
  from {
    right: -100%;
  }
  top {
    right: 0;
  }
}