.jumbotron_container {
  background-color: $colorMain;
  position: relative;
  padding: 15px;
  
  h1, p {
    color: white;
  }

  p {
    padding: 50px 15px;
  }

  .jumbotron_separator {
    background: white;
    width: 50px;
    height: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 960px) {
  .jumbotron_container {
    min-width: 100%;
    padding: 15px;
  }
}

@media screen and (min-width: 768px) {
  .jumbotron_container {
    max-width: 40%;
    padding: 15px;
    position: relative;
    top: -40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}