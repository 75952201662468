
// styles colors
$colorBackgroundAlternate: black;
$colorText: #545352;
$colorTextAlternate: white;
$colorMain: #B73B3B;
$colorAlternate: #F44F4F;
$colorAccent: #F01923;

// styles fonts
$fontTitle: 'Montserrat', sans-serif;
$fontP: 'Raleway', sans-serif;
$fontQuote: 'Playfair Display', serif;

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';

//dashboard...
@import 'dashboard/dashboard.scss';
@import 'dashboard/sidebar.scss';
@import 'dashboard/topbar.scss';
@import 'dashboard/filterbar.scss';
@import 'dashboard/login-form';

//components...
@import '404.scss';
@import 'navbar.scss';
@import 'image.scss';
@import 'video.scss';
@import 'jumbotron.scss';
@import 'leaflet-map.scss';
@import 'stats-block.scss';
@import 'page-title.scss';
@import 'quote.scss';
@import 'cookie-banner.scss';
@import 'gallery-viewer.scss';
@import 'trees-gfx.scss';
@import 'cows-gfx.scss';
@import 'content.scss';
@import 'full-content.scss';
@import 'buttons.scss';
@import 'contact-form.scss';
@import 'shoebox.scss';
@import 'modal.scss';

// react-image-gallery temp slideshow
@import "node_modules/react-image-gallery/styles/scss/image-gallery.scss";

// lightbox custom css
@import '~react-image-lightbox/style.css';
// video-react custom css
@import '~video-react/styles/scss/video-react';


html {
  text-align: center;
  font-family: $fontP;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.display-animation {
  padding-bottom: 100px;
}

.App-container {
  margin:0 auto;
}

h1 {
  color: black;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.15em;
}

h2 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
  letter-spacing:0.15em;
}

h3 {
  font-family: $fontTitle;
}

p {
  color: $colorText;
  font-family: $fontP;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15em;
}

a {
  color: white;

}

img {
  user-select: none;
  cursor: pointer;
}

input, textarea {
  outline: none;
}

textarea {
  height: 45px;
}

.container {
  max-width: 66%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.title_separator {
  background: black;
  width: 100px;
  height: 2px;

  &.center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

// MainHeader
.mainHeader_container {
  background: black;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 75vh;

  .mainTitle_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    opacity: 0.8;

    h1 {
      color: white;
    }
  }

  i {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    opacity: 0.3;
  }
}

.section_container {
  position: relative;
  min-height: 50vh;
}

.mainVideo {
  opacity: 0.8;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copyright_container {
  padding: 15px 0 5px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    display: inline-block;
    margin: 0 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.15em;
    a {
      color: black;
    }
  }

  p {
    font-size: 11px;
    text-align: center;
  }
}

// text helpers
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold {
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

.hidden {
  opacity: 0;
}


// preloader
#preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  border: #C4C4C4 3px solid;
  border-left-color: transparent;
  border-radius: 50%;
  -webkit-animation: rotating 1s linear infinite;
}

// preloader animation
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

// content animation
.display-animation {
  animation: displayAnimation 0.7s forwards ease-out;
}

@keyframes displayAnimation {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (max-width: 1250px) {
  .image-gallery-content {overflow: hidden}
  .image-gallery-slides {min-width:120%}
}

@media screen and (max-width: 960px) {
  .mainHeader_container {
    height: 500px;
  }

  .inView_container {
    width: 100%!important;
  }

  .section_container {
    flex-wrap: wrap;

    .content_container {
      width: 100%!important;
      order: 2;
    }
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 22px;
  }

  .container {
    max-width: 90%;
  }

  .mainHeader_container {
    height: 300px;

    video {
      width: 300%;
    }
  }
}
