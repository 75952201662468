.dashboard_intro {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}

.dashboard_container {
  margin: 0 auto;
  padding: 15px;

  h2 {
    margin-top: 100px;
  }

  .dashboard_container-card {
    background-color: white;
    border-radius: 5px;

    .dashboard_card {
      text-align: left;
      margin: 0 auto;
      max-width: 66%;
      
      label {
        text-align: left;
        margin-bottom: 15px;
        position: relative;
      }
      
      input, textarea {
        margin: 0 auto;
        padding: 15px;
        border-radius: 3px;
      }

      input[type=text] {
        width: 100%;
      }

      textarea {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        height: 200px;
      }

    }
  }
}


// dashboard form
.dashboard_form-section {
  margin: 15px 0;
  padding: 50px 30px 50px 30px;
  border-radius: 5px;
  background: #F2F2F2;

  table {
    tr {
      display: inline-block;
      vertical-align: top;
    }

    input {
      border: none;
      margin: 0;
      padding: 0!important;
      background: none!important;
    }

    td, th {
      padding: 15px;
      display: inline-block;
      width: 100%;
      &:nth-child(odd) {
        background: #e6e6e6;
      }
    }
    
    .appartment-name {
      width: 20%;
    }

    .appartment-status {
      width: 20%;
    }
  }

}

.dashboard_label-file {
  border: 1px dashed #C4C4C4;
  display: block;
  overflow: hidden;
  width: 100%;
  min-height: 200px;
  cursor: pointer;

  input {
    display: none;
  }
}

.dashboard_input {
  border: 1px solid #C4C4C4;
  margin: 15px 0!important;
}



@media screen and (max-width: 720px) {
  .dashboard_form-section {
    table {
      .appartment-name {
        width: 50%;
      }
      .appartment-status {
        width: 50%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .dashboard_form-section {
    table {
      .appartment-name {
        width: 40%;
      }
      .appartment-status {
        width: 60%;
      }
    }
  }
}