.filterbar_container {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #C4C4C4;
  text-align: right;

  .filterbar_searchbar {
    background: white;
    width: 600px;
    border-radius: 3px;
    
    input {
      display: inline-block;
      border: none;
      border-bottom: 1px solid #F2F2F2;
    }
    
    i {
      display: inline-block;
    }
  }

  .filterbar_menu {
    display: inline-block;
    margin-left: 15px;

    button {
      background: transparent;
      margin: 0 5px;
      border: none;
      cursor: pointer;

      &:hover {
        color: $colorAccent;
      }
    }
  }
}

// media gallery
.media_gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  label {
    max-width: 200px;
    min-height: 200px;
    cursor: pointer;
    border-radius: 3px;
    margin: 5px;
  }

  span {
    position: relative
  }

  .media_gallery-trash {
    background: white;
    border: none;
    border-radius: 3px;
    position: absolute;
    padding: 5px 8px;
    bottom: 2rem;
    opacity: 0.5;
    transition: opacity 0.4s ease;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    i {
      color: $colorAccent;
    }
  }

  input {
    display: none!important; // hide the original CSS for the input file
  }
}