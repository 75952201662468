
// video-react
.video-react {
    min-height: 100%;
}
.video-react-poster {
background-size: cover!important;
}

.video-react-controlBar {
display: none!important;
}