.form_container {
  max-width: 500px;
  margin:0 auto;
  padding: 60px 0;

  textarea {
    min-height: 50px;
  }

  label {
    display: block;
    color: $colorText;
    font-family: $fontP;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.15em;
    text-align: left;
    margin-top: 50px;
  }

  .form_input {
    display: block;
    color: black;
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 200px;
    padding: 15px;
    padding-bottom: 5px;
    font-family: $fontP;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.15em;
    border: none;
    border-bottom: 1px solid #CECECE;
    transition: border-bottom 0.32s ease;

    &:hover {
      border-bottom: 1px solid $colorAccent;
    }

    &:active, &:focus {
      border-bottom: 1px solid $colorAccent;
    }
  }

  button {
    margin-top: 50px;
  }
}


.form-error {
  color: red;
  font-weight: bold;
  margin-top: 5px;
  border-radius: 3px;
  text-align: left;

}

.recaptcha {
  margin-top: 15px;
}

// Contact page...
.contact_container {
  background-image: url('./../medias/contact_kinvest-001.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;

  .contact_card {
    background: white;
    position: absolute;
    padding: 50px;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .contact_card-content {
      position: relative;

      p {
        padding: 0 10%;
        letter-spacing: 0.05em;
      }
    }

    .contact_card-column {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    .contact_card-info {
      margin-top: 30%;
      padding: 50px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact_container {
    background: none;
    position: initial;
    height: auto;

    .contact_card {
      width: 100%;
      position: initial;
      transform: none;
      box-shadow: none;
      padding: 15px;

      .contact_card-info {
        margin-top: 0;
        padding: 15px;
      }

      .contact_card-column {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        position: static;
      }
    }
  }
}