.section_container {
  display: flex;
}

.content_container {
  height: 100%;
  padding: 50px;
  position: relative;

  &.black {
    background: black;
    color: white;

    .title_separator {
      background: white;
    }

    p {
      color: inherit;
    }
  }

  h2, p {
    padding-left: 30px;
    max-width: 500px;
  }
  h2 {
    margin-bottom: 40px;
  }
}

.content_svg {
  img {
    position: absolute;
    max-width: 666px;
    bottom: 0;
    left: 0;
    width: 90%;
    height: initial;
    margin: 15px 15px 0 15px;
    transform: scaleX(-1);
  }

  svg {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100px;
    margin: 0 15px 15px 0;
    transform: scaleX(1);
  }
}


@media screen and (max-width: 1250px) {
  .content_container {
    padding: 25px;
    min-height: 500px;
  }
}


@media screen and (max-width: 640px) {
  .content_container {
    padding: 15px;
    p {
      max-width: 100%!important;
      text-align: left!important;
    }
  }

}

.title_separator {
  &.white {
    background: white;
  }

}