.galleryViewer_container {
  background: white;
  color: black;
  padding: 50px 0;

  &.black {
    background: black;
    color: white;
  }

  img {
    transition: opacity 0.5s ease;
    position: relative;
    transform: scale(1);
    animation: imageAppear 0.6s ease-in-out;
    animation-fill-mode: backwards;
    padding: 1px;
    &:hover {
      opacity: 0.7;
    }
  }
}

.galleryViewer_carousel {
  background: red;
  padding: 50px 0;

  .card {
    background: black;
    width: 150px;
    height: 150px;
  }
}


@keyframes imageAppear {
  from {
    opacity: 0;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}